@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

#root {
  --blue: #1e90ff;
  --white: #ffffff;
  --yellow: #d89d1d;
  --error-red-color: #ff0000;
  --success: #008000;
  --off-white: #f2f2f2;
  --black: #0f172a;
  --secondary: #4c6072;
  --grey-white: #94a3b8;
  --primary-color: #00a88e;
  --grey-color: #94a3b8;
  --blue-color: #3862ee;
  --light-blue: #5398ff;
  --purpal-color: #8767ff;
  --light-purpal: #a65ce8;
  --ornage: #ee9953;
  --lightGreen: #09e9bf;
}

$primary-color: #00a88e;

* {
  box-sizing: border-box;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--bg-light);
}
::-webkit-scrollbar {
  background-color: var(--bg-light);
  width: 6px;
  height: 6px;
}

body {
  font-family: "Inter" !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
}

span {
  font-family: "Inter" !important;
}

html {
  scroll-behavior: smooth;
  font-family: "Inter" !important;
}

* body,
body,
button,
input,
select,
textarea,
h6,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Inter" !important;
  color: var(--secondary-color);
}

* {
  margin: 0;

  &:focus {
    outline: none !important;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-weight: 800 !important;
  }

  h1 {
    font-size: 32px;
    line-height: normal;
  }

  h2 {
    font-size: 24px;
    line-height: normal;
  }

  h3 {
    font-size: 18px;
    line-height: normal;
  }

  p {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none !important;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .w-full {
    width: 100%;
  }

  .h-full {
    height: 100vh;
  }

  .text-underline {
    text-decoration: underline;
  }

  .BorderRadius {
    border-radius: var(--border-radius-pixel);
  }

  .error {
    position: absolute;
    font-size: 12px;
    color: var(--error-red-color) !important;
    margin-left: 2px;
    margin-top: 1px;
  }

  .suggestion {
    font-size: 12px !important;
    margin-left: 2px !important;
    margin-top: 1px !important;
  }

  .suggestion-error,
  .profile-error {
    font-size: 12px !important;
    margin-left: 2px !important;
    margin-top: 1px !important;
    color: var(--error-red-color) !important;
  }

  .no-internet-connection {
    color: var(--secondary-color);

    img {
      height: 80px;
    }
  }
}

.nav-bar-wrapper {
  width: 100%;

  .nav-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-name-email {
      .setting-option {
        width: 100%;
        display: flex;
        align-items: center;

        img {
          width: 35px;
        }

        .bottom-email-sec {
          width: calc(100% - 30px);
          position: relative;
          padding: 0 0 0 14px;

          &.open-dropdown {
            .user-profile-text {
              img {
                -ms-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }
        }

        .option-dropdown {
          text-align: right;
          cursor: pointer;
          width: auto;
          display: inline-block;
          float: right;
          margin-top: -2px;

          img {
            cursor: pointer;
          }

          &:hover {
            color: var(--primary-color);
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.closed {
    margin-left: 68px;
  }
}

body {
  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }
}

.MuiAutocomplete-popper {
  z-index: 9999 !important;

  > div {
    box-shadow: var(--boxShadow-color) !important;
  }
}

.MuiToolbar-regular {
  min-height: 100%;
  padding: 30px;

  &.MuiTablePagination-toolbar {
    min-height: 100%;
    padding: 15px 30px;
    border-top: 1px solid var(--light-grey-color);
  }
}

.cus-head-logo {
  box-shadow: none !important;
  padding: 0;
  z-index: 999999;
  justify-content: center;
  margin: 20px 0 0px;

  .logo-icon {
    height: 42px;
  }

  .logo-icon-desktop {
    height: 30px;
    // margin-top: 30px;
  }
}

.MuiOutlinedInput-root.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--secondary-color);
    border-width: 2px;
  }
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0;
}

.nav-right {
  &.dashbord {
    padding-top: 14px;
  }

  span {
    &.badgec svg {
      height: 18px;
      width: 18px;
      margin-right: 4px;
    }
  }
}

span {
  &.badgec svg {
    height: 18px;
    width: 18px;
    margin-right: 4px;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: var(--secondary-color);
  font-weight: 900;
}

.table-loader {
  height: 350px;
  justify-content: center;
  display: flex;
  align-items: center;

  .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    color: color(secondary) !important;
  }

  /*** Login ***/
  /* End LogIn Css */
  .list-style {
    padding: 0;
    margin: 0;

    li {
      width: auto;
      list-style: none;
      margin: 0 0px 0 20px;

      &:first-child {
        margin: 0;
      }

      .select__control {
        min-width: 240px;
      }
    }

    .MuiTableCell-head {
      font-weight: 800;
    }

    img.toggle-btn {
      height: 18px;
    }

    .loader-circle-color {
      color: var(--color-white);
    }

    .button {
      &:hover {
        background-color: var(--black-light-color);
      }
    }

    // &.btn-custom-primary {
    //   color: var(--white);
    //   border-color: 1px solid var(--black-light-color);
    //   border: 1px solid var(--primary-color);
    //   &:hover,
    //   &:active {
    //     border-color: 1px solid var(--black-light-color);
    //     border: 1px solid var(--primary-color);
    //     outline: none;
    //   }
    //   &.MuiButton-outlined {
    //     border: 0;
    //   }
    // }
    &.selected {
      background-color: var(--primary-color);
      color: var(--color-white);
    }
  }

  .custom-modal {
    border-radius: 16px;
  }

  .heading-title-box {
    padding: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  #long-menu {
    > div {
      border-radius: 8px;

      ul {
        padding: 0;
      }
    }

    ul {
      li {
        border-bottom: 1px solid var(--light-grey-color);

        img {
          width: 12px;
          height: 12px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .back-sec {
    h3 {
      font-size: 20px;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }

    h2 {
      margin: 0;
      display: flex;
      align-items: center;
      line-height: normal;
      font-size: 24px;

      svg {
        font-size: 24px;
        height: auto;
        position: relative;
        top: -1px;
      }
    }
  }
}

main {
  .MuiInput-underline:after {
    border-bottom: 2px solid var(--secondary-color);
  }

  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }

  .MuiInputLabel-filled {
    transform: translate(12px, 14px) scale(1);
    -moz-transform: translate(12px, 14px) scale(1);
    -webkit-transform: translate(12px, 14px) scale(1);
    font-size: 16px;

    &.MuiInputLabel-shrink {
      transform: translate(12px, 0) scale(0.75);
      -moz-transform: translate(12px, 0) scale(0.75);
      -webkit-transform: translate(12px, 0) scale(0.75);
    }
  }

  .MuiFilledInput-input {
    padding: 16px 12px 10px;
  }

  .card-custom {
    height: 100%;
    border-radius: 12px;
    box-shadow: var(--boxShadow-color);
  }

  .MuiFormLabel-root {
    color: var(--border-color);
  }

  .title-heading {
    font-size: 20px;
    margin: 0;
  }

  .table-design {
    background: var(--color-white);
    box-shadow: var(--boxShadow-color) !important;
    border-radius: 5px;
    padding: 0px 0 0;
    margin-top: 20px;
    overflow: auto;

    tr.MuiTableRow-root {
      &.MuiTableRow-hover:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  .more-btn {
    color: var(--black-color);
    opacity: 1;
  }

  .table-program {
    tbody {
      td {
        color: var(--secondary-color);

        &.green {
          color: var(--success) !important;
        }

        svg {
          color: var(--secondary-color);
          height: 20px !important;
        }
      }

      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }

    th {
      line-height: normal;
      color: var(--secondary-color);
      background-color: var(--header-border) !important;
      font-weight: 700;

      svg {
        color: var(--secondary-color);
        height: 20px !important;
      }

      .icon {
        color: var(--secondary-color);
        height: 15px !important;
      }

      &.more {
        position: sticky;
        width: auto;
        right: 0;
        background-color: var(--offwhite);
      }
    }

    td {
      word-break: break-word;
      white-space: nowrap;

      button {
        padding: 0 !important;
        line-height: 0 !important;

        &:hover {
          background-color: transparent;
        }
      }

      &.more {
        position: sticky;
        width: auto;
        right: 0;
        background: var(--color-white);
      }

      .show-more-span {
        color: var(--error-red-color) !important;

        font-family: "Inter";
      }
    }

    th,
    td {
      padding: 12px 10px;
      min-width: 140px;
      white-space: nowrap;
      border-bottom: 1px solid var(--header-border);

      &:first-child,
      &:first-child {
        padding-left: 25px;
      }

      .more-button {
        background: var(--primary-color);
        padding: 4px 6px 2px 10px;
        border-radius: 30px;
        color: var(--color-white);
        font-size: 12px;
        text-align: center;
        display: inline-block;

        svg {
          color: var(--color-white) !important;
          width: 20px;
        }
      }

      .MuiChip-label {
        text-transform: capitalize;
      }
    }

    [type="button"]:not(:disabled) {
      cursor: pointer;
    }

    .partner-badge {
      border: 1px solid var(--light-grey-color);
      padding: 3px 10px;
      border-radius: 30px;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      text-transform: capitalize;
      margin-right: 4px;
    }
  }

  .table-design {
    display: grid;
    overflow: auto;

    > div {
      overflow: auto;
    }
  }
}

.user-profile-text {
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
  }

  .option-dropdown {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 15px;

    h5 {
      line-height: 0.8;
      font-size: 16px;
      font-weight: 700 !important;
      color: var(--secondary-color);
    }

    span {
      font-size: 12px;
    }
  }

  img {
    width: 22px;
  }
}

.logout-text {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #1e293b;
  cursor: pointer;

  svg {
    cursor: pointer;
    color: var(--white);
  }

  .option-dropdown {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    line-height: 0.8;
    font-size: 14px;
    color: var(--white);

    span {
      font-size: 12px;
    }
  }

  img {
    width: 22px;
  }
}

/** sidebar **/
.custom-menu {
  background: var(--black);

  > div {
    justify-content: space-between;
    background: var(--black);
    overflow: hidden;
    border-right: 1px solid var(--header-border);
  }

  .sidebar-menus {
    height: calc(100% - 70px);
    overflow: hidden;
    overflow-y: auto;
    border-right: 1px solid var(--black);
    padding: 10px 12px 0;
    margin-top: 0;

    &::-webkit-scrollbar-track {
      background-color: var(--color-white);
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: var(--color-white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-color);
      border-radius: 10px;
    }

    h6 {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600 !important;
    }

    .active {
      h6 {
        color: var(--primary-color);
      }
    }

    .sidepanel-item {
      margin: 0 0 10px;
      padding: 7px 12px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;

      &.active {
        opacity: 1;
        background-color: var(--primary-color);

        .less-icon {
          color: var(--white);
        }
      }

      .sidebar-menu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 18px;
        color: var(--grey-white);

        svg,
        img {
          width: 18px;
          height: 18px;
        }

        &.active {
          color: var(--white);
        }
      }

      .sidebar-menu-text {
        margin: 0 0 0 8px;
        color: var(--grey-white);
        font-size: 14px;

        &.active {
          color: var(--white);

          span {
            color: var(--white);
          }
        }

        span {
          font-size: 14px;
        }
      }

      &:hover {
        opacity: 1;
      }

      svg.MuiSvgIcon-root {
        fill: var(--white);
      }
    }

    > .sidepanel-item:first-child {
      .sidebar-menu-icon {
        img {
          width: 18px;
        }
      }
    }

    .MuiCollapse-entered {
      margin-right: 8px;
      overflow: hidden;

      .active {
        background: transparent !important;
        color: var(--primary-color) !important;

        span {
          color: var(--primary-color) !important;
        }
      }
    }
  }

  &.makeStyles-drawerClose-9 {
    .sidebar-menus {
      .sidepanel-item {
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
  }
}

.top-header {
  box-shadow: none !important;
  border-bottom: 1px solid var(--header-border);

  .header-inner {
    height: 69px;
    padding: 10px 24px;

    .nav-bar-wrapper {
      width: 100%;

      .device-list {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0;

        li {
          color: var(--text-main);
          border: 1px solid var(--black-color);
          padding: 8px 20px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          margin: 0 20px;
          font-weight: 600;
          cursor: pointer;

          &.selected-product {
            background-color: var(--black-color);
            color: var(--black-white);
          }
        }

        span {
          fill: var(--black-color);
        }
      }
    }
  }
}

.MuiDialog-root {
  z-index: 9999 !important;
}

// .logout-icon {
//   height: 24px;
//   width: 24px;
//   color: var(--primary-color);
//   cursor: pointer;
// }
// .remember-me {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
.show-hide {
  cursor: pointer;
}

body {
  .MuiSelect-select {
    &:focus {
      border-radius: 0;
      background-color: transparent;
    }
  }
}

.disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.green {
  color: var(--success) !important;
  font-weight: 600 !important;
}

.blue {
  color: #9e9e9e !important;
  font-weight: 600 !important;
}

.red {
  color: var(--error-red-color) !important;
  font-weight: 600 !important;
}

.white {
  color: var(--white) !important;
  font-weight: 600 !important;
}

.orangeColor {
  color: var(--ornage) !important;
  font-weight: 600 !important;
}
.lightGreen {
  color: var(--lightGreen) !important;
  font-weight: 600 !important;
}

.show-pointer {
  cursor: pointer;
}

.text-center {
  text-align: cetner !important;
}

//For Public Site
.text-left {
  text-align: left !important;
}

* {
  body {
    .DateRangePicker_picker {
      z-index: 99;
    }
  }
}

.yellow {
  color: var(--yellow) !important;
  font-weight: 600 !important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.flex-row {
  flex-direction: row !important;
}

main .table-program {
  .cursor_default {
    tbody td {
      cursor: auto;

      .cursor_pointer {
        cursor: pointer;
      }
    }
  }
}

.cursor_pointer {
  cursor: pointer;
}

.font-weight600 {
  font-weight: 600 !important;
}

.text_current_bal {
  color: var(--yellow) !important;
}

.hover_underLine:hover {
  text-decoration: underline;
}

.input-group {
  position: relative;

  .help-block {
    left: 0;
    position: absolute;
    bottom: -18px;
    color: var(--error-red-color);
  }
}

.logout_hover:hover {
  color: var(--yellow) !important;
}

.w-100 {
  width: 100%;
}

.h-20 {
  height: "20px";
}

.justify-content-center {
  justify-content: center !important;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

body {
  background-color: var(--bg-light) !important;

  .MuiAutocomplete-popper {
    ul {
      padding: 0 !important;
    }
  }
}

//manage location
.back-cursor {
  cursor: pointer;
}

/* new styling */
.dashboard {
  width: 100%;
}

div#chart,
.apexcharts-canvas {
  width: 100% !important;
}

.apexcharts-canvas svg {
  width: 100%;
}

.boxshadow-custom {
  background: var(--color-white);
  box-shadow: var(--boxShadow-custom) !important;
  -moz-box-shadow: var(--boxShadow-custom) !important;
  -webkit-box-shadow: var(--boxShadow-custom) !important;
  border-radius: 4px;
  color: var(--black-color);

  .input-group {
    button {
      color: var(--border-color);
    }
  }
}

.menu-icon {
  svg {
    color: var(--light-grey-color);
  }
}

.custom-table-pagination {
  border-top: 1px solid var(--border-main);
  padding: 10px 20px;

  .pagination-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .showing-items {
      display: flex;
      align-items: center;
      padding: 9px 20px;
      border-radius: 30px;
      color: var(--secondary-color);

      P {
        font-family: "Inter";

        span {
          color: var(--primary-color);
          font-weight: 600;
        }
      }

      svg {
        color: var(--primary-color);
        font-size: 18px;
        margin-left: 15px;
        cursor: pointer;
      }

      .table-pagination {
        margin-top: 15px;

        button {
          height: 20px;
          margin: 0 0;
          padding: 0 4px;
          min-width: 20px;
          color: var(--black-color);
        }
      }

      @keyframes refresh-svg {
        from {
          -webkit-transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
        }
      }

      .refresh-svg {
        animation: refresh-svg;
        -webkit-animation: refresh-svg 2s linear infinite;
      }
    }

    .MuiPagination-ul {
      .MuiPaginationItem-page {
        border-radius: 6px;
        color: var(--secondary-color);

        &.Mui-selected {
          border-radius: 6px;
          background-color: var(--primary-color);
          color: var(--white);
        }

        &:hover {
          background-color: var(--black-light-color);
        }
      }

      li {
        &:first-child button,
        &:nth-child(2) button,
        &:last-child button,
        &:nth-child(10) button {
          margin: 0;
          padding: 0 !important;
        }

        &.MuiPaginationItem-root {
          border-radius: 0;
        }

        .MuiPaginationItem-icon {
          color: var(--primary-color);
        }

        & > div {
          color: var(--secondary-color);
        }
      }
    }
  }
}

.brands-title {
  text-transform: capitalize;
}

.capitalize {
  text-transform: capitalize;
}

.edit-pop-up li {
  cursor: default;
}

.common-add-component {
  .header {
    padding: 0 0 20px 0;
    position: relative;

    .img-add-inner {
      height: 18px;
      margin-right: 10px;
    }

    // &:after {
    //   content: "";
    //   background: #dbdfe3;
    //   width: 103.3%;
    //   height: 2px;
    //   position: absolute;
    //   left: -26px;
    //   bottom: 0;
    // }
  }

  .content {
    padding: 20px;
  }

  .footer {
    // border-top: 1px solid var(--border-main);
    padding: 20px;

    .buttons-div {
      display: flex;
      float: right;

      button {
        min-width: 160px;
        margin: 0 10px;
        padding: 10px 16px;
      }
    }
  }
}

.textarea-input-field {
  width: 100%;
  border-radius: 4px;
  padding: 10px;

  font-family: "Inter";
  font-size: 1rem;
  border-color: var(--black-light-color);
  overflow: auto;
  resize: none;

  &::placeholder {
    color: var(--gray-color);
  }

  &:hover {
    border-color: var(--black-light-color);
  }

  &:focus {
    border-color: var(--black-color);
    border-width: 2px;
  }

  &.error-active {
    border-color: var(--error-red-color);
  }
}

.color-theme {
  color: var(--primary-color);

  &:hover {
    color: var(--secondary-color);
  }
}

/* Page Heading */

.location-container {
  padding: 25px;

  .location-head {
    .heading {
      h2 {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;

        svg {
          color: var(--primary-color);
          font-size: 32px;
          margin-right: 10px;
        }

        img {
          width: 24px;
          margin-right: 10px;
          height: 24px;
        }
      }
    }

    &.btn-edit-tooltip {
      .MuiTooltip-tooltip {
        font-size: 22px !important;
      }
    }

    .content-container {
      .show-more-span {
        color: var(--primary-color);
      }
    }

    .search-add {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;

      button {
        display: flex;
        align-items: center;
        &.filter-btn-style {
          position: absolute;
          right: 0;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 5px 0px 0px 5px;
          opacity: 1;
          border: 0;
          width: 60px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0 !important;
          cursor: pointer;
        }
      }

      .custom-search-field {
        input[type="text"] {
          padding: 12px 12px;
          margin: 0 16px;
          background: #fff;
          box-shadow: none;
          border: 1px solid #f2f2f2;
          color: var(--black-color);
          border-radius: 4px;
        }
      }
    }
  }

  .add-loader {
    svg {
      color: var(--color-white) !important;
    }
  }

  // .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
  //   color: var(--primary-color) !important;
  // }
}
.common-add-component {
  .custom-search-field {
    input[type="text"] {
      color: var(--secondary-color);
      background: none;
      border: 1px solid #bdbcbc !important;
      border-radius: 4px;
    }
  }
}

/*back btn*/
.back-btn {
  font-size: 14px;
  // text-transform: uppercase;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;

  .back-icon {
    width: 24px;
    background: var(--color-white);
    text-align: center;
    color: var(--primary-color);
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--gray-color);
    margin-right: 10px;
  }

  svg {
    width: 14px;
    color: var(--secondary-color);
    margin-left: 4px;
  }
}

.heading {
  display: flex;
}

.transaction-page {
  justify-content: flex-end;
}

.update-circular-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.astrick-sign {
  color: var(--error-red-color);
}

.download-csv {
  svg {
    border-radius: 50%;
    border: 1px solid;
    width: 44px;
    height: 44px;
    padding: 6px;
  }

  &.no-device {
    svg {
      color: var(--secondary-color);
    }
  }

  &.devices {
    svg {
      color: var(--primary-color);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.download-csv-imgicon {
  border-radius: 50%;
  border: 1px solid;
  width: 44px;
  height: 44px;
  padding: 7px;
  color: var(--primary-color);

  &.devices {
    svg {
      color: var(--primary-color);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.Csv-downloading {
  margin-top: 12px;
  padding-left: 10px;
}

.select-custom {
  position: relative;

  select {
    border-color: var(--light-grey-color);
    display: flex;
    align-items: flex-start;
    background: var(--light-grey-color);
    appearance: none;
    background: url("../../assets/download.png") no-repeat 82% / 11px #f1f1f1;
    border-radius: 30px;
    padding: 10px 16px;
    min-width: 80px;
    border: none;
    color: var(--black);
  }
}

.formgroup-help-icon {
  font-size: 18px !important;
  margin-left: 5px !important;
  margin-bottom: 2px !important;
}

/** **/

.input-text {
  margin: 0 0 24px !important;
  font-size: 14px !important;
  word-break: break-all;
  padding-right: 22px;
}

.show-table {
  th {
    font-size: 16px;
  }
}

.background-theme-color {
  padding: 0;
  margin: 40px 0 16px !important;
  border-radius: 4px 4px 0 0;
  position: relative;
  font-size: 24px;
  line-height: normal;
  color: var(--secondary-color);

  .error.ml {
    bottom: -18px;
    left: 0;
    font-weight: 400;
  }
}

.no-background-theme-color {
  background-color: var(--bg-light);
  color: var(--black-color);
  padding: 12px;
  margin: 24px 0 !important;
}

td.table-no-data {
  text-align: center;
  padding: 20px !important;
  font-size: 18px;
  font-weight: 600;
}

.update-circular-loader {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resend-signup-verification-dialog {
  padding: 0px 40px !important;
}

.input-custom-checkbox {
  label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementTop {
    margin: 0 0 10px;
    align-items: end;
  }

  .MuiSwitch-root {
    left: -12px !important;
  }
}

.input-custom-checkbox > div > div > div > p {
  color: var(--secondary-color);
}

.ota-select {
  .MuiSelect-select {
    width: 50px;
  }
}

.form-control {
  width: 100%;
  display: flex;
  border: 0px;
  box-shadow: none !important;

  input {
    height: 48px;
    width: 98px;
    padding: 10px;
    font-size: 16px;
    margin-left: 20px;
  }

  &::after,
  &::before {
    border: 0px;
    width: 0;
  }
}

.add-btn1 {
  .button-btn-add {
    height: 48px;
    background: var(--primary-color);
    color: var(--color-white);
    margin: 20px 20px 20px 0;

    &:hover {
      background-color: var(--black-color);
    }
  }
}

.drop-files {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;

  .cus-img-wrappe {
    margin-left: 10px;
    font-size: 10px;
  }
}

.modal-add-pdf {
  justify-content: center !important;
}

.custom-list-style {
  display: flex;
  align-items: center;
  list-style: none;
  background: var(--color-white);
  justify-content: center;
  padding: 9px 16px;
  &.livelink-target {
    padding: 0;
    background-color: transparent;
    li {
      margin: 0;
      background-color: var(--color-white);
      width: auto;
      &.target-version {
        width: auto;
      }
    }
  }

  li {
    margin-right: 20px;
    color: var(--secondary-color);

    &.target-version {
      width: 200px;

      label {
        background: var(--color-white);
      }

      .ota-select {
        .MuiSelect-select {
          width: 120px;
        }
      }
    }

    .button-btn {
      min-width: 130px;

      &.btn-custom-primary {
      }
    }
  }

  &.custom-line {
    font-size: 16px;
    padding: 20px;
    background: var(--color-white);
    color: var(--secondary-color);
  }
}

.add-file-dropzone {
  cursor: pointer;
  border: 2px dotted var(--gray-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 2px;
}

/* Timeline */
.location-container {
  width: 100%;
}

.timeline-header {
  margin-bottom: 40px !important;
}

.timeline {
  border-left: 8px solid var(--primary-color);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 20px 40px 40px;
  list-style: none;
  text-align: left;
  width: 100%;

  .event {
    // border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    padding-bottom: 25px;
    margin-bottom: 50px;
    position: relative;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: -217.5px;
      color: rgba(255, 255, 255, 0.4);
      content: attr(data-date);
      text-align: right;
      font-weight: 100;
      font-size: 0.9em;
      min-width: 120px;
    }

    &:after {
      box-shadow: 0 0 0 6px var(--primary-color);
      left: -52.85px;
      background: var(--color-white);
      border-radius: 50%;
      height: 18px;
      width: 18px;
      content: "";
      top: 5px;
    }
  }
}

div#support-document {
  border: 2px dotted var(--bg-light);
  padding: 9px;
  width: 100%;
}

.custom-dropzone-image-wrapper img {
  width: 48px !important;
}

#firmware {
  width: 100%;
}

.dropzone-text {
  text-align: center;
  font-size: 16px;
}

.image-custom-dropzone-div svg {
  width: 18px;
  height: auto;
  cursor: pointer;
}

.text-more {
  padding-left: 5px;

  &:hover {
    cursor: pointer;
    color: var(--primary-color);
  }
}

.OTA-all-device-selected {
  cursor: pointer;
  margin-top: 1px;
}

.table-program {
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(132 147 167);

  &:hover {
    background-color: transparent !important;
  }
}

.drop-down-custom {
  max-width: 260px;
  min-width: 260px;

  .MuiAutocomplete-tag {
    height: 22px;
    margin: 1px 3px;
    max-width: calc(100% - 6px);
    font-size: 14px;

    .MuiChip-deleteIcon {
      width: 18px;
      height: 16px;
      margin: 0 5px 0 -6px;
    }

    .MuiChip-label {
      padding-bottom: 1px;
    }
  }
}

.cus-img-wrappe {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .image-custom-dropzone-div {
    // justify-content: center;
    text-align: center;
  }
}

body {
  .table-design {
    .MuiTouchRipple-root {
      display: none;
    }
    .Mui-selected {
      .table-custom-select {
        .MuiInputLabel-outlined.MuiInputLabel-shrink {
          transform: translate(14px, -6px) scale(0.75) !important;
          background: var(--bg-light);
        }

        .MuiInputLabel-outlined {
          background: var(--bg-light);
        }
      }
    }

    .MuiTableRow-hover {
      &:hover {
        .table-custom-select {
          .MuiInputLabel-outlined.MuiInputLabel-shrink {
            // background: var(--bg-light);
          }

          .MuiInputLabel-outlined {
            // background: var(--bg-light);
          }
        }
      }
    }

    .table-custom-select {
      label {
        background: transparent;
        color: var(--secondary-color) !important;
        &:hover {
          background: transparent;
        }
      }

      .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(8px, 12px) scale(1) !important;
        pointer-events: none;
        font-size: 12px;
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75) !important;
      }
    }

    .MuiOutlinedInput-input {
      padding: 8.5px 14px;
      font-size: 14px;
    }
  }
}

.no-OTA {
  cursor: auto;
  opacity: 0.6;
  // background-color: var(--secondary-color) !important;
}

.show-ota-history {
  &:hover {
    color: var(--primary-color);
    cursor: pointer;
  }
}

.sidepanel-item {
  .less-icon {
    color: var(--secondary-color);
  }
}

.OTA-serial {
  color: var(--text-main);

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
    color: var(--primary-color);
  }
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  .MuiAutocomplete-input {
    &:first-child {
      padding-top: 6px;
      padding-bottom: 5px;
    }
  }
}

.input-section-edit {
  .error {
    position: relative;
    margin-bottom: 10px !important;
    text-align: left;
  }
}

.installtion {
  h4 {
    color: var(--primary-color);
    font-size: 18px;
    margin: 30px 0 10px !important;

    &:first-child {
      margin-top: 0 !important;
    }
  }

  strong {
    color: var(--primary-color);
  }

  li {
    margin: 0 0 8px;
    font-size: 15px;

    &::marker {
      color: var(--primary-color);
      font-size: 20px;
    }
  }

  p {
    margin-bottom: 12px !important;
    font-size: 15px;

    .text-green {
      color: var(--primary-color);
      font-weight: 900;
      font-size: 15px;
      text-transform: capitalize;
    }
  }
}

.add-agent-container {
  .po-rel {
    position: relative;
  }

  .error {
    left: 12px;
  }
}

.MuiInputLabel-outlined {
  transform: translate(14px, 16px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.copied-icon {
  padding-left: 12px;
  padding-bottom: 0px;
  height: 21px;
  padding-top: 4px;
}

.copied {
  color: var(--success) !important;
  font-weight: 600;

  .copied-text {
    margin-top: 3px;
  }

  .MuiSvgIcon-root {
    font-size: 18px;
    margin-top: 4px;
    margin-right: 2px;
  }
}

.datepicker {
  .DateRangePicker {
    .CalendarDay__selected {
      background-color: var(--primary-color) !important;

      &:active {
        background-color: var(--primary-color);
      }

      &:hover {
        background-color: var(--primary-color);
      }
    }

    .CalendarDay__selected_span {
      background-color: var(--primary-color);
    }
  }

  .DateRangePickerInput_clearDates__hide {
    visibility: initial !important;
  }

  .DateRangePickerInput_clearDates_default {
    &:focus {
      background: transparent;
      border-radius: 50%;
    }

    &:hover {
      background: transparent;
      border-radius: 50%;
    }
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: transparent;
    border: 1px double var(--black);
    color: var(--gray-color);
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    border: 1px double var(--color-white);
    color: var(--white);
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span:active {
    background: var(--primary-color);
    border: 1px double var(--color-white);
    color: var(--color-white);
  }

  .CalendarDay__selected_span {
    background-color: transparent;
    border: 1px double var(--color-white);
  }

  .DateRangePickerInput_arrow_svg {
    fill: var(--black-color);
  }

  .DateRangePickerInput {
    border-radius: 4px;
    border: 1px solid var(--black-color);
    display: flex;
    align-items: center;
    background-color: transparent;

    ul {
      li {
        margin: 0;
      }
    }

    .DateInput {
      border-radius: 4px;
      width: 110px;
      background-color: transparent;

      .DateInput_input {
        font-size: 14px;
        color: var(--secondary-color);
        border-radius: 4px;
        font-family: "Inter";
        background-color: transparent;

        &::placeholder {
          color: var(--black-color);
        }
      }

      .DateInput_input__focused {
        border-bottom: 2px solid transparent;
      }
    }

    .DateRangePickerInput_arrow {
      color: var(--black-color);
      width: 42px;
      margin: 0 auto;
      text-align: center;
    }

    .DateRangePickerInput_calendarIcon {
      padding: 0;
      margin: 0 10px 0 10px;

      svg {
        fill: var(--primary-color);
        width: 16px;
        height: 22px;
      }
    }
  }

  .DateInput_input {
    font-weight: 500;
    min-height: 48px;
    padding: 0 10px;
    line-height: 1.5;
  }

  .CalendarDay__default {
    &:hover {
      background: var(--primary-color);
    }
  }
}

.MuiAppBar-colorPrimary {
  color: var(--primary-color);
  background-color: var(--color-white) !important;
}

/** Show data Common CSS **/
.show-data {
  label {
    font-size: 14px;
    color: var(--secondary-color);
  }

  p {
    &.MuiTypography-colorTextSecondary {
      color: rgb(0 168 142);
    }
  }
}

.top-bottom {
  .MuiInputBase-root {
    color: var(--secondary-color);
  }
}

.justify-content-center {
  justify-content: center !important;
}

body .bg-white {
  background-color: var(--color-white);
}

.device-id {
  min-width: 300px !important;
}

body {
  .MuiOutlinedInput-input {
    padding: 14px;
    color: var(--black-color);
  }

  .MuiOutlinedInput-root {
    svg {
      color: var(--secondary-color);
    }
  }
}

.tariff-custom {
  padding: 16px;
  // margin: 0 15px 20px;
  border-radius: 4px;
  color: var(--black-color);
  border: 2px solid var(--black-color);

  p.input-text {
    display: flex;
    // justify-content: space-between;
    width: 100%;
    margin: 0 !important;
  }
}

.loader {
  width: 100px;
}

.upload-csv-file {
  display: flex;

  img {
    height: 48px;
    cursor: pointer;
  }

  aside + div > span {
    margin-top: 20px !important;
    background-color: var(--primary-color) !important;
  }
}

.no-data-found {
  width: 100%;
  text-align: center;
  justify-content: center;
  clear: both;
  padding: 0 0 20px;
  font-weight: 700;
  font-size: 18px;
  color: var(--text-main);
}

.btn-edit-tooltip {
  div {
    .MuiTooltip-tooltip {
      font-size: 22px !important;
      background-color: var(--error-red-color) !important;
    }
  }
}

/* Custom password tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 60px;
  width: 100%;

  .tooltiptext {
    background-image: url("../image/passwordCard.png");
    background-size: 100% 100%;
    margin-top: -15px;
    width: 100%;
    border-radius: 6px;
    position: absolute;
    z-index: 999;
    top: 150%;
    left: 0;
    margin-left: -75px;
    visibility: visible;
    display: block;
    padding: 25px 15px 10px 15px;

    &::after {
      content: none;
      position: absolute;
      margin-left: 20px;
      -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      width: 25px;
      height: 25px;
      transform: translateY(-15px) rotate(45deg);
      -webkit-transform: translateY(-15px) rotate(45deg);
      background: var(--color-white);
      display: block;
      top: 10px;
      left: 0;
      z-index: -1;
      border: 1px solid var(--gray-color);
    }

    svg {
      width: 15px;
    }

    span {
      font-weight: normal;
    }

    .password-heading {
      color: var(--black-light-color);
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      color: rgba(0, 0, 0, 0.87);
      padding: 10px 15px;
      position: relative;
      line-height: 1.8;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 15px;
        background: var(--gray-color);
        height: 1px;
        width: calc(100% - 30px);
      }
    }

    .main-e-box {
      .tooltip-error-text-container {
        padding: 3px 0;
        display: flex;
        display: -webkit-flex;
        align-items: center;
      }

      span {
        font-size: 14px;
      }
    }
  }
}

.location-container {
  .tooltip {
    .tooltiptext {
      width: calc(100% + 30px);
    }
  }

  .error {
    ~ {
      .tooltip {
        .tooltiptext {
          margin-top: -6px;
        }
      }
    }
  }

  .input-group {
    &.electricity-distri-com {
      fieldset {
        legend {
          width: 195px;
        }
      }
    }
  }

  .background-theme-color {
    label {
      color: var(--secondary-color);
      font-weight: bold;
    }
  }
}

/* Target Tracking Page Css Start */
.cus-main-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 154px);

  .cus-inner-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .page-heading {
      width: 100%;
      display: inline-block;
      text-align: center;
      padding: 10px 0;

      h2 {
        font-weight: bold;
        font-size: 40px;
        color: var(--text-main);
      }
    }

    .grid-heading {
      width: 100%;
      display: inline-block;
      text-align: center;
      margin-bottom: 7px;

      h3 {
        font-size: 35px;
        font-weight: bold;
      }
    }

    .cus-circle-chart {
      width: 100%;
      display: inline-block;
    }

    .cus-per-sec {
      width: 100%;
      display: table;
      height: 270px;
      align-items: center;
      position: relative;
      padding: 0 10px;

      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        list-style: none;
        height: 100%;
        align-items: center;
        justify-content: center;

        .row {
          display: inline-block;
          width: 100%;
          position: relative;
          margin: 10px 0;

          h3 {
            font-weight: bold;
            font-size: 30px;
            color: var(--brown-color);
            padding-left: 60px;

            span {
              font-weight: bold;
            }
          }

          &:after {
            content: "";
            height: 30px;
            width: 30px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            -ms-transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
            background-color: var(--primary-color);
          }

          &.dot-1 {
            &:after {
              background-color: var(--primary-color);
            }
          }

          &.dot-2 {
            &:after {
              background-color: var(--primary-color);
            }
          }

          &.dot-3 {
            &:after {
              background-color: var(--primary-color);
            }
          }
        }
      }
    }

    .left-card {
      padding-right: 15px;

      .cus-per-sec {
        .info {
          .row {
            h3 {
              padding-left: 0;
              padding-right: 60px;
              text-align: right;
            }

            &::after {
              left: auto;
              right: 0;
            }
          }
        }
      }
    }

    .right-card {
      padding-left: 15px;
    }

    .cus-date-sec {
      position: absolute;
      right: 20px;
      top: 35px;

      h3 {
        font-weight: bold;
        font-size: 18px;
        color: var(--brown-color);
      }
    }

    .cus-time-sec {
      position: absolute;
      right: 20px;
      top: 35px;

      h3 {
        font-weight: bold;
        font-size: 18px;
        color: var(--brown-color);

        > div {
          color: var(--brown-color) !important;
          width: auto !important;
          display: inline-block;
          text-transform: uppercase;

          span {
            min-width: 95px;
            display: inline-block;
            padding: 0 !important;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.cus-inner-container {
  .cus-middle-sec {
    height: calc(100% - 100px);
  }

  .cus-grid-rep {
    border: 3px solid var(--brown-color);
    border-top: none;
    height: 50%;

    &:first-child {
      border-left: none;
      border-right: none;
    }

    &:last-child,
    &:nth-child(2) {
      border-right: none;
    }

    &:nth-child(3) {
      border-right: none;
      border-left: none;
      border-bottom: none;
    }

    &:nth-child(4) {
      border-bottom: none;
    }

    &:nth-child(5) {
      border-bottom: none;
    }
  }
}

.part {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 0;
  flex-direction: column;
  height: 100%;

  h3 {
    color: var(--primary-color);
  }

  .count {
    font-size: 50px;
    color: #4c6072;
    font-weight: 900;
  }
}

.analytics-box {
  background-color: var(--color-white);
  color: var(--black-color);

  .info-icon {
    color: var(--grey-white);
  }

  .counts {
    h2 {
      font-size: 40px;
      font-weight: 600 !important;
    }

    h5 {
      font-size: 24px;
      font-weight: 600 !important;
    }
  }

  h2 {
    font-size: 20px;
    color: #8767ff;

    span {
      line-height: normal;
      padding: 20px 0;

      small {
        font-size: 12px;
        display: block;
        color: #94a3b8;
      }
    }

    .border-span {
      border-left: 1px solid var(--header-border);
      padding-left: 20px;
      margin-left: 20px;
      line-height: normal;
    }
  }

  .title {
    font-size: 14px;
    color: var(--black-color);
    font-weight: 600;
  }

  .filterOption {
    background: #94a3b829;
    border-radius: 50px;
    border-radius: 50px;
    width: 32px;
    height: 32px;

    p {
      color: var(--black-color);
      font-weight: normal;
      opacity: 1;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #94a3b8;
      }
    }
  }

  span {
    &.count-detail {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: normal;
      margin: 2px 0 8px;
      width: 100%;
    }
  }

  p {
    font-size: 14px;
    color: var(--grey-white);
  }

  .active-filter {
    &.filterOption {
      background-color: #05a88e29;

      svg#filter path {
        fill: #05a88e;
      }
    }
  }

  .filter-value {
    padding: 5px 17px;
    border-radius: 20px;
    border: 1px solid #05a88e;
    p {
      color: #05a88e;
    }
  }
}

.device-box {
  width: 20%;
  padding: 0 10px 20px;

  > div {
    height: 200px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 20px;
    color: var(--black-color);

    .d-flex {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      span {
        font-size: 14px;
        font-weight: 600;
        color: var(--black-color);

        img {
          margin-right: 6px;
        }
      }

      span + img {
        cursor: pointer;
      }
    }

    h2 {
      font-size: 32px;
      text-align: center;
      color: var(--black-color);
      display: flex;
      padding-top: 16px;
      justify-content: center;
      width: 100%;

      span {
        line-height: normal;
        padding: 20px 0;

        small {
          font-size: 12px;
          display: block;
          color: #94a3b8;
        }
      }

      .border-span {
        border-left: 1px solid var(--header-border);
        padding-left: 20px;
        margin-left: 20px;
        line-height: normal;
      }
    }

    p {
      font-weight: 600;
      font-size: 20px;
    }
  }

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }
}

.apexcharts-yaxis-label {
  margin-right: 10px;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 6px;
  max-width: 300px;
}

.progress-done {
  background: #ffb400a1;
  border-radius: 20px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.rating-container {
  min-width: 500px;
  max-width: 600px;
  margin: 20px auto;

  .left-side-card {
    width: 35%;
    text-align: center;
    margin-top: 6px;

    .user-review-text {
      font-weight: 900;
      font-size: 20px;
    }

    .rating-text {
      font-size: 40px;
      margin-top: 18px;
      font-weight: 800;
    }

    .review-text {
      font-weight: 700;
      font-size: 16px;
      margin-top: 4px;
    }

    label {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }
  }

  .right-side-card {
    width: 65%;
    margin: 20px 10px 0px 30px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 10px 0px;

        span {
          width: 20px;
          display: flex;
        }

        .progress {
          background-color: #d8d8d8;
          border-radius: 20px;
          margin: 0;
          height: 6px;
          display: inline-block;
          width: 70%;
        }

        .progress-done {
          background: #ffb400a1;
          border-radius: 20px;
          color: #e5f1d3;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: 1s ease 0.3s;
        }
      }
    }
  }
}

.clear-btn {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  padding: 0px 30px 0px 30px;
  font-size: 18px;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;

  span {
    font-size: 14px;
    font-weight: bold;
  }
}

.modal-title {
  .close {
    display: flex;
    justify-content: flex-end;
  }
}

.add-ota-container {
  display: flex;
  justify-content: center;

  h1 {
    margin-bottom: 22px !important;
  }

  .dialog-bottom-btns {
    padding-top: 20px;
  }
}

.table-custom-width {
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    background: var(--color-white);
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 10px) scale(1) !important;
    pointer-events: none;
  }

  .ota-select {
    .MuiSelect-select {
      width: 80px;
    }
  }
}

.date-picker {
  .DateRangePicker {
    min-width: 490px;
  }
}

.rating-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 60px;
  width: 100%;

  .rating-tooltiptext {
    background-image: url("../image/passwordCard.png");
    background-size: 100% 100%;
    margin-top: -15px;
    width: 100%;
    border-radius: 6px;
    position: absolute;
    z-index: 999;
    top: 150%;
    left: 0;
    margin-left: -75px;
    visibility: visible;
    display: block;
    padding: 25px 15px 10px 15px;

    &::after {
      content: none;
      position: absolute;
      margin-left: 20px;
      -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      width: 25px;
      height: 25px;
      transform: translateY(-15px) rotate(45deg);
      -webkit-transform: translateY(-15px) rotate(45deg);
      background: var(--color-white);
      display: block;
      top: 10px;
      left: 0;
      z-index: -1;
      border: 1px solid #d3d3d3;
    }

    svg {
      width: 15px;
    }

    span {
      font-weight: normal;
    }
  }
}

.replace-device-color {
  background-color: #80808047;
}

.csv-download-container {
  display: flex;
  justify-content: space-around;

  .partition-line {
    width: 2px;
    height: 30px;
    background-color: var(--primary-color);
  }
}

.force-sync-datepicker {
  padding-top: 4px !important;

  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
}

.trial {
  width: 30px !important;
  color: var(--success) !important;
  // text-decoration: underline;
  // cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}

.trial-no {
  width: 30px !important;
  color: var(--error-red-color);
  // text-decoration: underline;
  // cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}

.history-button {
  font-weight: bold;
  background-color: var(--primary-color);
  color: var(--white);
  text-decoration-line: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  min-width: 100px;
  text-align: center;
  margin-left: 5px;
  cursor: pointer !important;

  &.trial {
    width: 30px !important;
    color: #008000;
  }

  &.trial-no {
    width: 30px !important;
    background-color: var(--error-red-color) !important;
    color: red;
  }
}

.MuiPickersModal-dialog {
  .MuiPickersDay-daySelected:hover {
    background-color: #00a88e !important;
  }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover {
  background-color: white !important;
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementTop {
  margin: 0 0 20px !important;
  align-items: center !important;
}

.sidebar-menus {
  .device-heading {
    padding: 8px 0 10px 10px;
    font-size: 18px;
    font-weight: 600;
    color: var(--secondary-color);
  }

  .device-list {
    list-style: none;
    padding-left: 8px;
    font-family: "Inter";
    font-weight: 600;
    color: var(--secondary-color);

    li {
      // margin-bottom: 8px;
      font-size: 16px;
      padding: 10px 0px 16px 0;
    }

    .selected-product {
      opacity: 1;
      background-color: var(--transparent-color);
    }
  }

  .product-divider {
    background-color: #4c6072;
    height: 2px;
    margin: 10px 0 20px 0;
  }
}

.drop-down-custom.bg-white.boxshadow-custom {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6px;
  }
}

.goto-pagination {
  .MuiOutlinedInput-input {
    padding: 16px !important;
    font-size: 14px;

    svg {
      color: var(--white);
    }
  }
}

.bg-transparent {
  > div {
    background-color: transparent !important;
  }
}

.cus-tabs {
  padding-top: 14px;

  button {
    height: 40px;
    min-width: auto;
    background: transparent;
    border-radius: 5px;
    padding: 0 14px;
    min-height: auto;
    margin-right: 10px;
    text-transform: capitalize;

    span {
      &.MuiTab-wrapper {
        color: var(--grey-color);
      }
    }

    &.Mui-selected {
      background: var(--grey-color) 0% 0% no-repeat padding-box;

      span {
        &.MuiTab-wrapper {
          color: var(--white);
        }
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-flexContainer {
    padding: 0;
  }
}

.cus-tab-con {
  .MuiBox-root {
    padding-top: 0px !important;
  }
}

.blue-color {
  color: var(--blue-color) !important;
}

.purpal-color {
  color: var(--purpal-color) !important;
}

.light-blue {
  color: var(--light-blue) !important;
}

.light-purpal {
  color: var(--light-purpal) !important;
}

.box-style {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid var(--header-border);
  border-radius: 4px;

  li {
    padding: 48px 16px;
    border: 1px solid var(--header-border);
    width: 50%;
    display: inline-block;
    border-top: 0;
    border-left: 0;
    text-align: center;

    .badge-text {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px 0 20px;

      .text-style {
        color: var(--black-color);
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -1px;
        line-height: normal;
      }

      .badge {
        // background: #00e396 0% 0% no-repeat padding-box;
        color: var(--black-color);
        border-radius: 15px;
        opacity: 1;
        // width: 30px;
        text-align: center;
        display: table;
        padding: 2px 12px;
        font-weight: 600;
      }
    }

    p {
      font-size: 13px;
      color: #94a3b8;
      font-weight: 600;
    }

    &:last-child {
      border-bottom: 0;
      border-right: 0;
    }

    &:nth-child(2) {
      border-right: 0;
    }
  }
}

.height-100 {
  height: 100%;
}

.csv-download-container {
  display: flex;
  justify-content: space-between;

  .partition-line {
    width: 2px;
    height: 55px;
    background-color: red;
  }
}

.rs-anim-fade.rs-anim-in {
  opacity: 1;
  pointer-events: unset;
  z-index: 9999;
}

* body #menu- {
  z-index: 9999 !important;
}

.select-input-custom > div {
  background: transparent;
  box-shadow: none !important;
  border-color: var(--border-color);

  .css-b8ldur-Input {
    color: var(--color-black);
  }

  &:hover {
    border-color: var(--border-color) !important;
  }
}

.customer-csv {
  .download-container {
    margin: 0 50px;

    .csv-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--black-color);
      padding: 8px 14px;
      margin-bottom: 15px;
      border-radius: 6px;
      color: var(--black-color);

      .download-icon {
        padding: 2px;
        border-radius: 5px;
        background: var(--primary-color);
        cursor: pointer;

        img {
          padding: 2px;
        }
      }
    }
  }
}

.select-custom {
  .MuiSelect-select {
    padding: 14px;
    // color: var(--white) !important;
  }
}

.device-detail {
  .select-custom {
    .MuiSelect-select {
      padding: 14px;
      color: var(--black-color) !important;
      text-align: left;
    }
  }
}

/** login Screen Scss Here **/

.login-custom-width {
  background: url("../../assets/image/background-img.png");
  height: 100vh;
  background-position: center center;
  background-size: cover;

  .logo-wrapper {
    margin: 10px 0 40px;
    text-align: center;

    img {
      margin: 0 auto;
      width: 150px;
      height: 100px;
    }
  }

  h2 {
    text-align: center;
    color: var(--white);
    font-size: 28px;
    font-family: "Inter" !important;
    font-weight: 600 !important;
    text-transform: uppercase;
  }

  .login-form {
    width: 100%;
    justify-content: flex-end;
    height: 100vh;
    display: flex;
    align-items: center;

    form {
      &.full-w {
        max-width: 650px;
        min-width: 620px;
        margin: 0 auto;
        padding: 30px 80px 10px;
        background: #1b2439 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
        max-height: calc(100vh - 20px);
        overflow: auto;

        &.custom-width {
          width: 100%;

          input {
            height: 42px;
            padding: 0 14px;
          }

          .remember-me {
            label {
              margin: 0;
              text-align: left;

              > span {
                font-size: 14px;
                color: var(--grey-white);
                padding: 0 0;
                margin-right: 6px;
              }
            }

            &.label-password {
              justify-content: space-between;
              display: flex;
              align-items: center;
              margin: 0 0 16px;
            }
          }

          .sign-in-btn {
            width: 180px;
            font-size: 18px;
            margin: 24px 0;
            height: 42px;
          }

          .terms {
            letter-spacing: 0px;
            color: var(--grey-white);
            margin: 40px 0 0;
            font-size: 13px;
          }
        }

        .MuiOutlinedInput-input {
          padding: 14px;
          color: var(--white);

          svg {
            color: var(--white);
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: var(--grey-white) !important;
        }
      }

      label {
        color: var(--grey-white);
      }

      svg {
        path {
          fill: var(--grey-white);
        }
      }

      .password-field {
        span.help-block {
          &.error.text-left {
            bottom: 0;
            position: relative;
            color: var(--error-red-color) !important;
          }
        }
      }

      .checkbox-terms {
        font-size: 13px;
        color: var(--white);
        margin-top: 40px;

        label {
          margin: 0 4px 0 0;
          padding: 0;

          span {
            padding: 0;
          }
        }

        a {
          color: var(--primary-color);
        }
      }

      .button-btn {
        &.btn-custom-primary {
          &:hover {
            color: var(--primary-color);
            background: var(--white) !important;
            border-color: transparent;
          }
        }
      }
    }
  }
}

.login-forgot-password {
  cursor: pointer;
  display: inline-block;
  color: var(--grey-white);
  width: 100%;
  font-size: 13px;
  text-align: right;

  &:hover {
    color: var(--primary-color);
  }
}

/** Login Screen Scss End **/

.safelink-firmware-error {
  color: red;
  display: flex;
  justify-content: start;
  width: 100%;
}

.tableMoreInfo {
  font-size: 15px;
  color: black;
  background-color: "#fff";
  padding: 10px;
  word-break: "break-word";
  max-width: 400px;
}

// analytics styles

.filter {
  background: #2a3347;
  width: 300px;

  .filter-title {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #94a3b81f;
    padding: 14px;

    span {
      color: #fff;
    }
  }

  .version-filter {
    .MuiFormControlLabel-label {
      font-size: 13px;
    }
    .MuiSvgIcon-root {
      font-size: 20px;
    }

    .MuiCheckbox-root {
      color: #8493a7;
    }

    .MuiCheckbox-colorSecondary {
      &.Mui-checked {
        color: #01a88e;
      }
    }
  }

  .MuiCheckbox-colorSecondary {
    &.Mui-checked {
      color: #01a88e;
    }
  }

  .date-filter {
    .MuiFormControlLabel-label {
      font-size: 13px;
    }

    .MuiRadio-root {
      color: #8493a7 !important;
    }

    .MuiIconButton-colorSecondary {
      color: #8493a7 !important;
    }

    .MuiIconButton-colorSecondary {
      &.Mui-checked {
        color: #01a88e !important;
      }
    }
  }

  .MuiFormControlLabel-label {
    color: #fff !important;
  }
}

.filter {
  input[type="checkbox"] + svg path {
    fill: #c5c6c7;
  }

  .version-filter {
    .Mui-checked {
      input[type="checkbox"] + svg path {
        fill: #01a88e !important;
      }
    }
  }

  .rs-picker-toggle-wrapper {
    padding: 0 20px;
  }
}

.otpContainer {
  margin: 5% auto;
  background: #1b2439 0% 0% no-repeat padding-box;
  height: 500px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .button-btn {
    &.btn-custom-primary {
      &:hover {
        color: var(--primary-color);
        background: var(--white) !important;
        border-color: transparent;
      }
    }
  }
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 1.7rem;
  text-align: center;
  border-radius: 4px;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 12px;
  font-weight: 600;
}

.text-resend {
  color: var(--primary-color);
  font-size: 16px;
}
.range-filter-select {
  .MuiInputLabel-outlined {
    color: var(--gray-color);
  }
}
.box-item {
  flex: 1 1 auto;
}

.Mui-select {
  .MuiFormControl-root {
    .MuiInputLabel-formControl {
      transform: translate(14px, 24px) scale(1) !important;
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(14px, 2px) scale(1) !important;
        font-size: 12px;
      }
    }
  }
}

.menu-select {
  background: rgba(249, 250, 251, 0.08);
  border-radius: 0 !important;
  > * {
    outline: none !important;
    border: none;
  }
}

.grid-layout-set {
  display: contents;
}

.sidebar-select-module {
  width: 100%;
  display: inline-block;
  margin: 15px 0;
  padding: 0;
  .select-label,
  .menu-select span {
    color: var(--off-white);
  }
  .MuiFormControl-root {
    width: 100%;
    fieldset {
      border: none;
    }
  }
}

.alert-msg {
  color: orange;
  background: rgb(255 176 0 / 10%);
  display: inline-block;
  padding: 15px 30px;
  border-radius: 3px;
}

.update-sec {
  .MuiFormGroup-root {
    flex-direction: row;
    padding-right: 40px;
    label {
      .MuiRadio-root {
        color: var(--white);
        &.Mui-checked {
          color: var(--primary-color);
        }
      }
    }
  }
}

// Filter Dropdown UI
body {
  .MuiPopover-paper:has(.filter-popover-sec) {
    width: 300px;
    margin-top: 5px;
  }
}
.filter-popover > div {
  background: var(--color-white);
}
.filter-popover-sec {
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: end;
  align-items: end;
  gap: 15px;
  flex-direction: column;
  .clear-filterlink {
    color: var(--secondary-color);
    cursor: pointer;
    display: inline-flex;
    justify-content: end;
    width: fit-content;
  }
  .MuiBox-root {
    width: 100%;
    select {
      width: 100%;
      min-height: 45px;
      border: 1px solid #bdbcbc;
      border-radius: 4px;
      padding: 5px 10px;
      color: var(--secondary-color);
      font-size: 14px;
      background: none;
      option {
        color: #4c6072;
      }
    }
    .rs-picker {
      .rs-btn {
        border: 1px solid #bdbcbc !important;
        border-radius: 4px;
        min-height: 45px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        input {
          background-color: #ffffff;
        }
        .rs-picker-toggle-caret {
          margin-top: 3px;
        }
        // .rs-picker-toggle-placeholder {
        //   position: relative;
        //   top: 7px;
        //   font-size: 14px;
        //   color: #4c6072;
        // }
      }
    }
    .rs-picker-toggle-value {
      color: var(--secondary-color) !important;
      letter-spacing: 0;
      font-weight: 500 !important;
      // margin-top: 6px !important;
    }
    .rs-btn-close {
      top: 12px !important;
    }
  }
  button {
    &.button-btn {
      padding: 10.25px 10px;
      width: 100%;
    }
  }
}
.disableBtn {
  opacity: 0.5;
}
.border-column {
  border: 2px solid #bfbfbf;
  border-radius: 4px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  .input-text {
    margin-bottom: 0 !important;
    padding: 0;
  }
}
